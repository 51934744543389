import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItemText,
  ListItem,
} from "@mui/material";
import { Reorder } from "framer-motion";
import {
  benefitsAccordion,
  serviciosIndubatch,
} from "../utils/textosIndubatchPage";

import { useTranslation } from "react-i18next";

const BenefitsIndubatch = () => {
  const [panelExpanded, setPanelExpanded] = useState(0);
  const [servicesIndubatch, setServicesIndubatch] =
    useState(serviciosIndubatch);

  const handleChangePanel = (panel) => {
    setPanelExpanded(panel);
  };

  const { t } = useTranslation();

  return (
    <div id="beneficiosSection">
      <div className="beneficiosTitle ">
        <div className="tituloSeccion">Beneficios</div>
        <div className="tituloSeccion">Servicios</div>
      </div>
      <div className="benefitsContent">
        <div className="accordionContainer">
          {benefitsAccordion.map((benefit, index) => {
            return (
              <Accordion
                key={index}
                className="accordionElement"
                sx={{}}
                onChange={() => handleChangePanel(index)}
                expanded={panelExpanded === index}>
                <AccordionSummary
                  className="tituloAccordion"
                  aria-controls="panel-content"
                  id="panel-header">
                  <div className="iconoBenefitsAccordion">{benefit.icono}</div>
                  <Typography>{t(benefit.titulo)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t(benefit.text)}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
        <div className="informationAccordionSectionContainer">
          <div className="listaServiciosIndubatch">
            <Reorder.Group
              values={servicesIndubatch}
              onReorder={setServicesIndubatch}>
              {servicesIndubatch.map((item) => (
                <Reorder.Item
                  key={item}
                  value={item}
                  className="itemListaServiciosIndubatch">
                  {t(item)}
                </Reorder.Item>
              ))}
            </Reorder.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsIndubatch;
