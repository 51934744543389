import React from "react";
import { AppBar, Box, Button, Link, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { contactoInfo } from "../utils/textosMainPage";
import { linksRedesSociales } from "../utils/textosMainPage";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <Box
      id="contact"
      sx={{
        height: "30vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "40px",
      }}>
      <div className="contactusTitle tituloSeccion">{t("contactTitle")}</div>
      <Box className="contactusContainer">
        <div className="contactoLeft">
          <div className="contactoIconosTitle">{t("Followus")}</div>
          <div className="contactoIconosContainer">
            {linksRedesSociales.map((link, index) => {
              return (
                <Tooltip title={link.tooltip} key={index} placement="bottom">
                  <a target="_blank" rel="noreferrer" href={link.url}>
                    {link.icono}
                  </a>
                </Tooltip>
              );
            })}
          </div>
        </div>
        <div className="contactoRight">
          <div className="contactoInfoInfo">
            <a
              className="contactoLinkCorreo"
              href={`mailto:${contactoInfo.correo}`}>
              {contactoInfo.correo}
            </a>
            <span>{contactoInfo.direccion}</span>
            <span>{contactoInfo.telefono}</span>
            <span>{contactoInfo.ciudad}</span>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Contact;
