import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        hello: "Hello",
        aboutUs: "About us",
        changeLang: "Change language",
        ourProducts: "Our products",
        aboutusTitle: "About us",
        aboutusText:
          "We are experts in process management supported by our technological platforms, ensuring compliance with corporate policies and business objectives. We integrate with our clients' systems, especially SAP ERP, guaranteeing a single source of information and avoiding reprocessing, which translates into greater operational efficiency.",
        aboutusTextSecondParagraph:
          "Our team has extensive experience in systematizing administrative, commercial, and marketing processes in the fast-moving consumer goods sector, as well as batch record management in industrial plants. This has allowed us to apply technology effectively, resulting in greater control and management. Much of the operational tasks are automated through our platforms, allowing personnel to focus on value-added tasks for the business.",
        footerLeft: "Developed by BPMco",
        footerRight: "Working through processes is possible and easy",
        imgConecUsu1: "Connecting",
        imgConecUsu2: "5500 users",
        imgConecUsu3: "We manage",
        imgConecUsu4: "5 million",
        imgConecUsu5: "of activities",
        imgConecUsu6: "300",
        imgConecUsu7: "cities",
        ourProductsTitle: "Our products",
        descriptionIndubatch:
          "InduBatch is a computer platform that allows for the systematization of documents and the batch record process in industrial plants (the complete history of a manufacturing batch). Of great importance to the pharmaceutical, cosmetic, and food industries, with the aim of ensuring the final product quality and regulatory compliance.",
        descriptionProcess:
          "Technological platform to systematize, automate, and integrate the processes of our clients, starting from the analysis and diagnosis phases, through design and implementation, providing visibility and control from beginning to end over their processes, offering quality information for better decision-making.",
        descriptionMerco:
          "MerCo is a technological platform that supports strategic management and marketing operations at point of sale, through mobile devices, integrating with the client's other systems, providing timely and quality information for decision-making purposes.",
        ourClients: "Our clients",
        ourClientsTitle: "Our clients",
        contact: "Contact us",
        contactTitle: "Contact us",
        titleAboutIndubatch: "What is INDUBATCH",
        aboutIndubatchPt1:
          "InduBatch is a computer platform that allows for the systematization of documents and the batch record process in industrial plants (complete history of a manufacturing batch).",
        aboutIndubatchPt2:
          "Of great relevance to the pharmaceutical, cosmetic, and food industries, with the aim of ensuring the final product quality and compliance with regulations.",
        atencion: "Attention",
        beneficioAtencion:
          "Facilitates the handling of requests, complaints, and grievances by providing an efficient and accessible process for users, allowing them to express their concerns clearly and promptly.",
        cumplimiento: "Compliance",
        beneficioCumplimiento:
          "Supports compliance with legal requirements and international standards by ensuring that all requests, complaints, and grievances are handled in accordance with relevant regulations and globally recognized best practices.",
        documentacion: "Documentation",
        beneficioDocumentacion:
          "Ensures accurate, permanent, complete, and truthful documentation by implementing rigorous systems and procedures that guarantee appropriate collection, recording, and storage of all information related to the received requests, complaints, and grievances.",
        ibServ1: "Weighing and dosing records of raw materials",
        ibServ2: "Production order",
        ibServ3: "Packaging order",
        ibServ4: "Weight control records",
        ibServ5: "Manufacturing protocols and procedures",
        ibServ6: "Cleaning and clearance records",
        ibServ7: "Operating conditions",
        ibServ8: "Analysis certificates",
        ibServ9: "Coding verification",
        ibServ10: "Inspection report",
        ibServ11: "Destruction record",
        aboutIndubatch: "What is Indubatch",
        benefitsIndubatch: "Benefits",
        Followus: "Follow us",
        privacyAdvice: "Privacy Notice",
        dataManagementAdvice: "Information Handling Policy",
      },
    },
    es: {
      translation: {
        hello: "Hola",
        aboutUs: "Quiénes somos",
        changeLang: "Cambiar idioma",
        aboutusTitle: "Quiénes somos",
        aboutusText:
          "Somos expertos en gestión por procesos apoyados en nuestras plataformas tecnológicas, asegurando el cumplimiento de las políticas corporativas y objetivos empresariales. Nos integramos con los sistemas de nuestros clientes especialmente ERP SAP, arantizando una única fuente de información y evitando reprocesos, lo cual se traduce en una mayor eficiencia operacional.",
        aboutusTextSecondParagraph:
          "Nuestro equipo cuenta con una alta experiencia en la sistematización de procesos administrativos, comerciales, de mercadeo en el sector de consumo masivo, gestión del batch record en plantas industriales; lo que nos ha  ermitido aplicar la tecnología de manera acertada generando mayor control y gestión, pues gran parte de las tareas operativas se  utomatizan a través de nuestras plataformas, permitiendo focalizar al personal en tareas que agregan valor al Negocio.",
        footerLeft: "Desarrollado por BPMco",
        footerRight: "Trabajar por procesos es posible y fácil",
        imgConecUsu1: "Conectando",
        imgConecUsu2: "5500 usuarios",
        imgConecUsu3: "Gestionamos",
        imgConecUsu4: "5 millones",
        imgConecUsu5: "de actividades",
        imgConecUsu6: "300",
        imgConecUsu7: "ciudades",
        ourProducts: "Nuestros productos",
        ourProductsTitle: "Nuestros productos",
        descriptionIndubatch:
          "InduBatch es una plataforma informática quepermite sistematizar los documentos y el proceso del batch record en las plantas Industriales (historial completo de un lote de fabricación). De gran relevancia para la industria farmacéutica, cosmética y  de alimentos, con el objetivo de asegurar la calidad final del producto y el cumplimiento de la normatividad.",
        descriptionProcess:
          "Plataforma tecnológica para sistematizar, automatizar e integrar los procesos de nuestros clientes, partiendo desde las fases de análisis y diagnóstico, pasando por el diseño y la implementación, dándole visibilidad y control de principio a fin sobre sus procesos, brindando información de calidad para una mejor toma de decisiones.",
        descriptionMerco:
          "MerCo es una plataforma tecnológica de apoyo ala gestión estratégica y la operación de mercadeo en los puntos de venta, a  través de dispositivos móviles, la cual se integra con los otros sistemas del cliente, brindándole información oportuna y de calidad para la toma de decisiones.",
        ourClients: "Nuestros clientes",
        ourClientsTitle: "Nuestros clientes",
        contact: "Contáctanos",
        contactTitle: "Contáctanos",
        titleAboutIndubatch: "Qué es INDUBATCH",
        aboutIndubatchPt1:
          "InduBatch es una plataforma informática que permite sistematizar los documentos y el proceso del batch record en las plantas Industriales (historial completo de un lote de fabricación).",
        aboutIndubatchPt2:
          "De gran relevancia para la industria farmacéutica, cosmética y de alimentos, con el objetivo de asegurar la calidad final del producto y el cumplimiento de la normatividad.",
        atencion: "Atención",
        beneficioAtencion:
          "Facilita la atención de peticiones, quejas y reclamos al ofrecer un proceso eficiente y accesible para los usuarios, permitiéndoles expresar sus inquietudes de manera clara y oportuna.",
        cumplimiento: "Cumplimiento",
        beneficioCumplimiento:
          "Apoya el cumplimiento de los requerimientos legales y estándares internacionales al asegurar que todas las peticiones, quejas y reclamos sean manejados de acuerdo con las regulaciones pertinentes y las mejores prácticas reconocidas a nivel global.",
        documentacion: "Documentación",
        beneficioDocumentacion:
          "Garantiza la documentación exacta, permanente, completa y veraz al implementar sistemas y procedimientos rigurosos que aseguran la recopilación, registro y almacenamiento adecuados de toda la información relacionada con las peticiones, quejas y reclamos recibidos.",
        ibServ1: "Registros de pesaje y dosificación de materias primas",
        ibServ2: "Orden de producción",
        ibServ3: "Orden de empaque",
        ibServ4: "Registro de control de peso",
        ibServ5: "Protocolos y procedimientos de fabricación",
        ibServ6: "Registros de limpieza y despeje",
        ibServ7: "Condiciones de operación",
        ibServ8: "Certificados de análisis",
        ibServ9: "Verificación de codificación",
        ibServ10: "Reporte de inspección",
        ibServ11: "Acta de destrucción",
        aboutIndubatch: "Qué es Indubatch",
        benefitsIndubatch: "Beneficios",
        Followus: "Síguenos",
        privacyAdvice: "Aviso de privacidad",
        dataManagementAdvice: "Política de tratamiento de información",
      },
    },
  },
  lng: "es",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
