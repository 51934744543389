import React from "react";
import { Main } from "../indubatchSections/Main";
import AboutIndubatch from "../indubatchSections/AboutIndubatch";
import MarqueeIndubatch from "../indubatchSections/MarqueeIndubatch";
import ReviewsIndubatch from "../indubatchSections/ReviewsIndubatch";
import BenefitsIndubatch from "../indubatchSections/BenefitsIndubatch";
import Contact from "../homeSections/Contact";
import Footer from "../components/Footer";
import SeccionLegal from "../homeSections/SeccionLegal";

const IndubatchPage = () => {
  return (
    <div className="transicionEntrada">
      <Main />
      <AboutIndubatch />
      <BenefitsIndubatch />
      {/* <ReviewsIndubatch /> */}
      {/* <MarqueeIndubatch /> */}
      <SeccionLegal />
      <Contact />
      <Footer />
    </div>
  );
};

export default IndubatchPage;
