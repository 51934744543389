import React from "react";
import conectandoUsuariosImg from "../assets/imagesPaginaAnterior/conectandoUsuarios.png";
import { useTranslation } from "react-i18next";

const ConectandoUsuarios = () => {
  const { t } = useTranslation();
  return (
    <div id="conectandoUsuarios">
      <div className="conectandoUsuariosContainer">
        <div className="conectandoUsuariosLabel" id="cu1">
          {t("imgConecUsu3")}
          <span className="conectandoUsuariosRemarcado">
            {t("imgConecUsu4")}
          </span>{" "}
          {t("imgConecUsu5")}
        </div>
        <div className="conectandoUsuariosLabel" id="cu2">
          {t("imgConecUsu1")}
          <span className="conectandoUsuariosRemarcado">
            {t("imgConecUsu2")}
          </span>
        </div>
        <div className="conectandoUsuariosLabel" id="cu3">
          <span className="conectandoUsuariosRemarcado">
            {" "}
            {t("imgConecUsu6")}
          </span>{" "}
          {t("imgConecUsu7")}
        </div>
        <img src={conectandoUsuariosImg} alt="Conectando usuarios img" />
      </div>
    </div>
  );
};

export default ConectandoUsuarios;
