import React, { useState } from "react";
import { AppBar, Box, Button, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

const AboutIndubatch = () => {
  const [invertido, setInvertido] = useState(false);

  const { t } = useTranslation();
  return (
    <Box
      id="aboutIndubatch"
      sx={{
        height: "80vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
      <div className="tituloSeccion">{t("titleAboutIndubatch")}</div>
      <div className="aboutIndubatchContainer">
        <div
          className={`aboutIndubatchTexts ${invertido && "invertido"}`}
          onClick={() => setInvertido(!invertido)}>
          <div>{t("aboutIndubatchPt1")}</div>
          <div>{t("aboutIndubatchPt2")}</div>
        </div>
        <div className={`aboutIndubatchVideo ${!invertido && "invertido"}`}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/jNLzgBsfPZM?si=rqEAcAg9arFo5OYM"
            title="Video Indubatch"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </div>
      </div>
    </Box>
  );
};

export default AboutIndubatch;
