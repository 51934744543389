import { BiSupport } from "react-icons/bi";
import { FaCheckSquare } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";

import placeholder from "../assets/placeholder.jpg";

export const linksNavbarIndubatch = [
  {
    nombre: "About Indubatch",
    texto: "aboutIndubatch",
    link: "aboutIndubatch",
  },
  {
    nombre: "Beneficios",
    texto: "benefitsIndubatch",
    link: "beneficiosSection",
  },
  {
    nombre: "Contáctanos",
    texto: "contact",
    link: "contact",
  },
];

export const reviewsClientesIndubatch = [
  {
    nombre: "John Doe",
    img: placeholder,
    review:
      "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae consequuntur sint impedit .",
  },
  {
    nombre: "Jane Doe",
    img: placeholder,
    review:
      "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae consequuntur sint impedit culpa voluptate.",
  },
  {
    nombre: "Joe Dohn",
    img: placeholder,
    review:
      "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae consequuntur sint impedit culpa voluptate. Impedit, numquam maiores. Laborum pariatur offic",
  },
];

export const benefitsAccordion = [
  {
    icono: <BiSupport />,
    titulo: "atencion",
    text: "beneficioAtencion",
  },
  {
    icono: <FaCheckSquare />,
    titulo: "cumplimiento",
    text: "beneficioCumplimiento",
  },
  {
    icono: <IoDocumentText />,
    titulo: "documentacion",
    text: "beneficioDocumentacion",
  },
];

export const serviciosIndubatch = [
  "ibServ1",
  "ibServ2",
  "ibServ3",
  "ibServ4",
  "ibServ5",
  "ibServ6",
  "ibServ7",
  "ibServ8",
  "ibServ9",
  "ibServ10",
  "ibServ11",
];
