// Imágenes productos
import logoIndubatch from "../assets/productos/logoIndubatch.png";
import logoProcess from "../assets/productos/logoProcess.png";
import logoMerco from "../assets/productos/logoMerco.png";

// Imágenes clientes
import logoEPA from "../assets/clientes/EPA.png";
import logoICASA from "../assets/clientes/ICASA.png";
import logoLevapan from "../assets/clientes/Levapan.png";
import logoBonlac from "../assets/clientes/Bonlac.png";
import logoCasaLuker from "../assets/clientes/CasaLuker.png";
import logoJGB from "../assets/clientes/JGB.png";
import logoColgatePalmolive from "../assets/clientes/ColgatePalmolive.png";
import logoNorgas from "../assets/clientes/Norgas.png";
import logoCafeDuran from "../assets/clientes/CafeDuran.png";
import logoPascual from "../assets/clientes/Pascual.png";
import logoChec from "../assets/clientes/Chec.png";

// Logos redes sociales
import { IoLogoInstagram } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

// Documentos legales
import avisoDePrivacidad from "../docs/Aviso de Privacidad BPMCO S.A.S.pdf";
import politicaDeTratamientoInformacion from "../docs/Política de Tratamiento de Información_BPMCO S.A.S.pdf";

export const linksNavbar = [
  {
    nombre: "About us",
    texto: "aboutUs",
    link: "aboutUs",
  },
  {
    nombre: "Our products",
    texto: "ourProducts",
    link: "ourProducts",
  },
  {
    nombre: "Our clients",
    texto: "ourClients",
    link: "ourClients",
  },
  {
    nombre: "Contact us",
    texto: "contact",
    link: "contact",
  },
];

export const ourProducts = [
  {
    titulo: "Indubatch",
    descripcion: "descriptionIndubatch",
    imagen: logoIndubatch,
  },
  {
    titulo: "Process",
    descripcion: "descriptionProcess",
    imagen: logoProcess,
  },
  {
    titulo: "Merco",
    descripcion: "descriptionMerco",
    imagen: logoMerco,
  },
];

export const ourClients = [
  {
    nombre: "Empresa Panameña de Alimentos",
    imagen: logoEPA,
  },
  {
    nombre: "ICASA",
    imagen: logoICASA,
  },
  {
    nombre: "Bonlac",
    imagen: logoBonlac,
  },
  {
    nombre: "Levapan",
    imagen: logoLevapan,
  },
  {
    nombre: "Casa Luker",
    imagen: logoCasaLuker,
  },
  {
    nombre: "JGB",
    imagen: logoJGB,
  },
  {
    nombre: "Colgate-Palmolive",
    imagen: logoColgatePalmolive,
  },
  {
    nombre: "Norgas",
    imagen: logoNorgas,
  },
  {
    nombre: "Café Duran",
    imagen: logoCafeDuran,
  },
  {
    nombre: "Pascual",
    imagen: logoPascual,
  },
  {
    nombre: "Chec",
    imagen: logoChec,
  },
];

export const contactoInfo = {
  correo: "contacto@bpmco.co",
  direccion: "Cl. 63 # 21 - 17",
  ciudad: "Manizales",
  telefono: "+57 310 4874785",
};

export const linksRedesSociales = [
  {
    icono: <IoLogoInstagram />,
    url: "https://www.instagram.com/bpmco_sas/",
    tooltip: "Instagram",
  },
  {
    icono: <FaFacebook />,
    url: "https://www.facebook.com/p/BPMCO-SAS-100034760453288/",
    tooltip: "Facebook",
  },
  {
    icono: <FaLinkedin />,
    url: "https://co.linkedin.com/company/bpmco-sas",
    tooltip: "LinkedIn",
  },
];

export const botonesSeccionLegal = [
  {
    titulo: "Aviso de privacidad",
    documento: avisoDePrivacidad,
    text: "privacyAdvice",
  },
  {
    titulo: "Política de tratamiento de información",
    documento: politicaDeTratamientoInformacion,
    text: "dataManagementAdvice",
  },
];
