import React from "react";
import { botonesSeccionLegal } from "../utils/textosMainPage";
import { useTranslation } from "react-i18next";

const SeccionLegal = () => {
  const { t } = useTranslation();
  return (
    <div id="legalSection">
      <div className="seccionLegalContainer">
        <div className="btnContainer">
          {botonesSeccionLegal.map((btn, index) => {
            return (
              <a
                className="btnDocsLegales"
                key={index}
                href={btn.documento}
                target="_blank"
                rel="noreferrer">
                {t(btn.text)}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SeccionLegal;
