import "./App.css";
import { ThemeProvider } from "@mui/material/styles";

import Navbar from "./components/Navbar";
import { useState } from "react";
import lightTheme from "./mui-theme/lightTheme";
import darkTheme from "./mui-theme/darkTheme";

import { useTranslation } from "react-i18next";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import MainPage from "./pages/MainPage";
import IndubatchPage from "./pages/IndubatchPage";

function App() {
  const [theme, setTheme] = useState(lightTheme);

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme === lightTheme ? darkTheme : lightTheme
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Navbar changeLanguage={changeLanguage} />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/Indubatch" element={<IndubatchPage />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
