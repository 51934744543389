import React from "react";

import { AppBar, Box, Button, Link } from "@mui/material";

import { useRef } from "react";
import { motion } from "framer-motion";

import { useTheme } from "@mui/material/styles";

import logoProcesos from "../assets/Procesos.png";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const theme = useTheme();

  const constraintsRef = useRef(null);

  const { t } = useTranslation();

  return (
    <Box id="aboutUs" className="aboutusContainer" sx={{}}>
      <div className="aboutusTitle tituloSeccion" id="tituloSeccionAboutus">
        {t("aboutusTitle")}
      </div>
      <div className="aboutusContent">
        <motion.div className="aboutusDragArea" ref={constraintsRef} />
        <motion.div
          className="aboutusDraggableDiv"
          drag
          dragConstraints={constraintsRef}
          style={{
            // backgroundColor: `${theme.palette.contrast.main}`,
            display: "flex",
            flexDirection: "column",
            height: "max-content",
            padding: "15px",
          }}>
          <div className="aboutusText">{t("aboutusText")}</div>
        </motion.div>
        <motion.div
          className="aboutusDraggableDiv"
          drag
          dragConstraints={constraintsRef}
          style={{
            // backgroundColor: `${theme.palette.contrast.main}`,
            display: "flex",
            flexDirection: "column",
            height: "max-content",
            padding: "15px",
          }}>
          <div className="aboutusText">{t("aboutusTextSecondParagraph")}</div>
        </motion.div>
      </div>
    </Box>
  );
};

export default AboutUs;
