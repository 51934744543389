import React from "react";
import Home from "../homeSections/Home";
import AboutUs from "../homeSections/AboutUs";
import OurProducts from "../homeSections/OurProducts";
import OurClients from "../homeSections/OurClients";
import Contact from "../homeSections/Contact";
import Footer from "../components/Footer";
import ConectandoUsuarios from "../homeSections/ConectandoUsuarios";
import SeccionLegal from "../homeSections/SeccionLegal";

const MainPage = () => {
  return (
    <div className="transicionEntrada">
      <Home />
      <AboutUs />
      <ConectandoUsuarios />
      <OurProducts />
      <OurClients />
      <SeccionLegal />
      <Contact />
      <Footer />
    </div>
  );
};

export default MainPage;
